export const ArabicLabel = {
  Cutomerpanel: "بوابة المستخدم",
  login: "تسجيل الدخول",
  Register: "انشاء حساب",
  en: "انجليزي",
  Person: "شخصي",
  Business: "تجاري",
  Phonenumber: "رقم الهاتف",
  Continue: "متابعة",
  phonenumberhasnotregisterd: "رقم الهاتف غير مسجل",
  Username: "اسم المستخدم",
  title: "اللقب",
  Password: "كلمة السر",
  ExOnro: "مثال: 12345",
  Exstart: "Ex. ******",
  googleConnected: "Google connected",
  facebookConnected: "Facebook connected",
  appleConnected: "Apple Id connected",
  plsenterphonnumber: "يرجى ادخال رقم الهاتف",
  firstnnameloginplaceholder: "مثال: محمد",
  lastnameloginplaceholder: "مثال: عامر",
  Firstname: "الاسم الاول",
  Lastname: "اسم العائلة ",
  email: "البريد الالكتروني",
  emailPlaceholder: "(اختياري) Ex. example@example.com",
  comingsoon: "قريباً:",
  comingsoondesc: "لتسجيل عملك ، يرجى النقر هنا ",
  contactUs: "اتصل بنا",
  comingsoondesc2: "يرجى التواصل معنا لإنشاء حساب تجاري",
  CustomerAlreadyRegistered: "المستخدم مسجل مسبقاً، يرجى تسجيل الدخول",
  registerCompleted: "ادخل رمز التفعيل",
  googletokenerror: "Problem with Google Token",
  facebooktokenerror: "Problem with Facebook Token",
  appletokenerror: "Problem with Apple Token",
  CustomerNotRegistered: "المستخدم غير مسجل",
  fr: "فرنسي",
  InvalidVerificationCode: "رمز التفعيل غير صحيح",
  Usernotfound: "لم يتم العثور على معلوماتك",
  welcome: "اهلا",
  SmsLimitationError: "يرجى المحاولة مرة اخرى خلال دقائق",
  resendCodeWithTime: "اعادة ارسال رمز التفعيل (XXX)",
  resendCode: "اعادة ارسال رمز التفعيل",
  VerificationcodePlaceHolder: "مثال: 1234",
  Verificationcode: "رمز التفعيل",
  VerificationcodeText: "ادخل الرمز الذي تم ارساله إلى xxx",
  VerificationCodeExpired: "انتهت صلاحية رمز التفعيل",
  Pending: "قيد الانتظار",
  Unassigned: "قيد الانتظار",
  Assigned: "قيد الانتظار",
  PickedUp: "قيد الانتظار",
  Started: "قيد الانتظار",
  statusInProgress: "قيد الانتظار",
  Done: "منجز",
  CustomerCanceled: "ملغي",
  DriverCanceled: "ملغي",
  SupportCanceled: "ملغي",
  Arrived: "قيد الانتظار",
  new: "جديد",
  Historyofyourorderswillbehere: "تفاصيل طلباتك السابقة ستظهر هنا",
  otherdropoffs: "وجهات اخرى",
  Orderhistory: "تفاصيل الطلب",
  Noorder: "لا يوجد طلبات",
  loadingList: "يرجى الانتظار",
  loadingError: "يوجد خطأ بالحصول على المعلومات",
  retry: "حاول مرة اخرى ",
  Create_your_first_requst: "إنشاء طلب جديد",
  List_of_you_active_orders_will_be_here: "قائمة الطلبات الفعالة ",
  Aboutus: "من نحن",
  Readour: "اقرأ",
  privaypolicy: "سياسة الخصوصية",
  Transactions: "العمليات الشرائية",
  NoTransaction: "لا يوجد لديك عمليات شرائية",
  Historyofyourransactionswillbehere: "قائمة عملياتك الشرائية",
  Settings: "الإعدادات",
  Language: "اللغة",
  English: "الانجليزية",
  Persian: "فارسي",
  French: "فرنسي",
  Logout: "تسجيل الخروج",
  logotTitle: "تسجيل الخروج من الحساب؟",
  logoutDescription: "سيتم تسجيل خروجك من الحساب ويجب عليك تسجيل الدخول لاحقًا",
  confirmLogout: "نعم، سجّل الخروج",
  skipLogout: "إلغاء",
  savesettings: "حفظ الإعدادات",
  emailInvalid: "البريد الالكتروني غير صحيح",
  Neworder: "طلب جديد",
  Service: "الطرد",
  poperLoading: "يرجى الانتظار",
  Pickup: "استلام",
  SetPickup: "تحديد الاستلام",
  ChooseoOnMap: "خريطة",
  Fornow: "الآن",
  Scheduled: "موعد محدد",
  before: "قبل: ",
  after: "بعد: ",
  confirm: "تأكيد",
  Settime: "تحديد الوقت",
  fo: "إلى",
  Cancel: "الغاء",
  Shipmentlabel:"تسمية الشحن",
  Pleasetryanotherkeyword: "يرجى محاولة كلمة اخرى",
  Noinformationfound: "لم يتم العثور على اي معلومات",
  address: "العنوان",
  block: "المبنى",
  Floor: "الطابق",
  Unit: "الشقة او المكتب",
  senderfullname: "اسم المرسل الكامل",
  senderphonenumber: "رقم موبايل المرسل",
  notesfordriver: "ملاحظة للسائق",
  DropOff: "التسليم",
  SetDropOff: "حدد التسليم",
  fetchingdata: "يتم استلام المعلومات",
  Recieverfullname: "اسم المستلم الكامل",
  Recieverphonenumber: "رقم موبايل المستلم",
  undefined: "نوع الخدمة",
  PromotionCode: "رمز الخصم",
  Delete: "الغاء",
  Save: "حفظ",
  PromoCodeValid: "تم حفظ رمز الخصم",
  SenderWallet: "المحفظة",
  SenderCash: "نقداً",
  ReceiverCash: "الدفع من خلال المستلم",
  lowBalance: "الرصيد غير كافي",
  Balance: "الرصيد",
  Methods: "طرق الدفع",
  balanceError: "يجب ان يكون رصيدك على الأقل مساويا لسعر الطلب ",
  SendOrder: "ارسل الطلب",
  feedback: "التقييم",
  Feedback: "التقييم",
  Orderagain: "اطلب مرة اخرى",
  Createdsuccessfully: "تم انشاء طلبك بنجاح",
  chooseAmount: "اختر القيمة",
  orenterthepreferedamount: "القيمة المطلوبة:",
  enteramount: "السعر المطلوب",
  pay: "ادفع",
  selectPaymentGateway: "اختر بوابة الدفع",
  Time_MachineText: "هل تريد استرجاع المعلومات السابقة؟",
  yes: "تعم",
  ignore: "تجاهل",
  Order: "الطلب",
  statusPending: "قيد الانتظار",
  statusUnassigned: "قيد الانتظار",
  statusAssigned: "قيد الانتظار",
  statusStarted: "قيد الانتظار",
  statusPickedUp: "تم الاستلام",
  statusDone: "منجز",
  statusCustomerCanceled: "ملغي",
  statusDriverCanceled: "ملغي",
  statusArrived: "قيد الانتظار",
  statusSupportCanceled: "ملغي",
  statusCanceled: "ملغي",
  Phone: "رقم الهاتف",
  additonalservice: "خدمات اضافية",
  CallDriver: "اتصل بالسائق",
  Message: "ارسل رسالة",
  orderInfoError: "خطأ بالوصول للمعلومات",
  support: "خدمة العملاء",
  map: "الخريطة",
  receipt: "الوصل",
  photo: "صورة",
  ServicePhoto: "صورة الطرد",
  Addphoto: "اضافة صورة",
  Estime: "الوقت المتوقع",
  Espath: "الطريق المتوقع",
  pod: "اثبات التسليم",
  Notes: "الملاحظات",
  Photo: "صورة",
  Signature: "التوقيع",
  Nonotes: "لا يوجد ملاحظات",
  Nosignature: "لا يوجد توقيع",
  Nophoto: "لا يوجد صورة",
  Price: "السعر",
  Receiver: "من المستلم",
  Sender: "من المرسل",
  Cash: "نقداً",
  Canceltravel: "الغاء الطلب",
  CancellText: "سيتم الالغاء عند السائق بعد الغاء الطلب",
  Estimated: "المتوقع",
  Wallet: "الرصيد",
  Copylink: "انسخ الرابط",
  repeatOrder: "اعادة الطلب",
  Edit: "تعديل",
  confirmCancel: "نعم",
  paymentmethod: "طريقة الدفع",
  SucceessFul: "تم بنجاح",
  errorinServer: "خطأ الاتصال بالخادم",
  Comment: "تعليق",
  Exfeedbackinput: "مثال: تم التوصيل بسرعة!",
  Wallett: "المحفظة",
  TypeHere: "اكتب هنا",
  Loadingmessages: "يتم تحميل الرسائل",
  PoweredBy: "بدعم من ",
  newincomemessage: "وصلتك رسالة جديدة",
  newincomemessageanother: "لديك رسالة جديدة لطلب اخر",
  youhaveunreadmessage: "رسائل غير مقروءة",
  Nointernetconnection: "لا يوجد اتصال بالأنترنت",
  close: "اغلاء",
  seen: "قرئت",
  controlThatbeforeLoweEqualAfter: "يرجى التأكد من الوقت",
  controlThatbeforeAndAfterMustBiggerthanCurrentDate: "يرجى التأكد من الوقت",
  errorInPanelSetting: "حدث خطأ في عملية الحجز، يرجى التواصل مع الدعم الفني ",
  Arabic: "عربى",
  ar: "عربى",
  veeDeliveryLoginText1: "ابعت طلباتك واستلمها",
  veeDeliveryLoginText2: "بأسرع وقت  مع VEE Delivery NOW!",
  Schedule: "تحديد موعد",
  Date: "التاريخ",
  Time: "الوقت",
  noDateToShow: "لم يتم تحديد التاريخ",
  noTimeToShow: "لم يتم تحديد الوقت",
  Apply: "تم",
  Increaseyourbalance: "زيادة رصيدك",
  AddBalance: "إضافة الرصيد",
  
  Addressbook:"دليل العناوين",
  AddressbookSearchbar:"البحث حسب العنوان، العنوان والمعرف",
  Removedefault:"إزالة الافتراضي",
  Setdefaultpickup:"تعيين التقاط الافتراضي",
  Edit:"يحرر" ,
  Remove :"يزيل",
  newAddress :"عنوان جديد",
  NewAddressbookInfoTitle:"معلومات",
  Addressdetails:"تفاصيل العنوان" ,
  Title :"عنوان",
  Code: "شفرة",
  editAddress :"تعديل العنوان",
  AddressbookDropOf:"دفتر العناوين (انزلاق)",
  AddressbookPickup:"دفتر العناوين (بيك اب)",
  hide:"إخفاء",
  Nosavedaddress:"لا يوجد عنوان محفظ",
  NosavedaddressDesc:"يمكنك إنشاء واحدة من خلال النقر فوق الرمز المرجعية بجوار العنوان أو الذهاب إلى صفحة دفتر العناوين",
  Romanian:"روماني",
  Vehicletype:"نوع المركبة" ,
  Pickup2:"استلام" ,
  Dropoff2:"تسليم" ,
  senderfullname: "اسم المرسل",
  block: "مبنى",
  senderphonenumber: "رقم المرسل",
  Recieverfullname: "اسم المستلم",
  Recieverphonenumber: "رقم المستلم",


  
  Events: "الاشعارات",
  allEvents: "جميع الاشعارات",
  star :"نجمة",
  Yourrate:"تقييمك" ,
  Comment:"تعليق",
  CopyOrderlink:"انسخ رابط الطلب",
  CopyPickuplink:"انسخ رابط الاستلام" ,
  CopyDeliverylink:"انسخ رابط التسليم",
  ro:"روماني",

  Dutch:"إنجليزي",
  nl:"إنجليزي",
    
  paymentmethod: "طريقة الدفع او السداد",
  Name :"اسم",
  CashOnDelivery:"الدفع عند الاستلام",
  EnterAmount:"أدخل المبلغ",
  COD:"الدفع عند الاستلام",
  Surchargexxx:"رسوم إضافية xxx.",
  Pricedetails: "تفاصيل الأسعار",
  minxxx :"xxx دقيقة",
  toPickup:"للالتقاط" ,
  toDropOff: "للانزلاق",
  AddCreditCard:"إضافة بطاقة الائتمان / الخصم" ,
  CustomerWallet:"محفظة العملاء" ,
  Add:"يضيف",
  Addedsuccessfully: "اضيف بنجاح",
  paymentmethods: "طرق الدفع",
  NoCards:"لا بطاقات ",
  Listofyourcardswillbehere:"قائمة البطاقات الخاصة بك سيكون هنا",
  CardInfo:"معلومات بطاقة",
  ExpireDate:"تاريخ انتهاء الصلاحية",
  CVC:"CVC.",
  PostalCode:"رمز بريدي",
  RemoveCardq:"إزالة البطاقة؟",
  RemoveCardA:"ستتم إزالة البطاقة ولا يمكن استخدامها بعد الآن",
  YesRemove:"نعم، أخرج",
  Cardisinuse:"البطاقة قيد الاستخدام",
  ok:"موافق",
  Pleasetypeyourwordstosearch:"الرجاء كتابة كلمتك الرئيسية للبحث",
  spanish:"الأسبانية",
  es : "الأسبانية",
  Invoices:"فواتير",
  noInvoice:"xxx رقم",
  germany: 'ألمانيا',
  de: 'ألمانيا',
  downloadClientApplication:"قم بتنزيل تطبيق xxx" ,


  "AssignedAt":"تعيين حتى xxx",
  "StartedAt":"ابدأ حتى xxx",
  "ArrivedAt":"تصل حتى xxx",
  "PickedUpAt":"بيك آب في xxx",
  "DoneAt":"الانتهاء في xxx",
  Russian:"الروسية",
  Azerbaijani:"أذربيجاني",
  rus:"الروسية",
  aze:"أذربيجاني",
  Support:"الدعم",
  SendRequest:"ارسل طلب",
  CallTheSupport:"استدعاء الدعم",
  Choosethesubject :"اختر الموضوع",
  Seefrequentquestions:"انظر الأسئلة المتكررة",
  Details:"تفاصيل",
  Send:"إرسال",
  MessageBox:"مربع الرسالة",
  NoMessages:"لا توجد رسائل",
  Yourmessageswillbehere:"ستكون رسائلك هنا",
  Portuguese:"البرتغالية",
  pt:"البرتغالية",
  Required:"مطلوب",
  changePassword : "تغيير كلمة المرور",
  changePasswordp1 : "سيتم تسجيل الدخول من جميع الجلسات باستثناء هذه الجلسات لحماية حسابك الذي يحاول أي شخص الوصول إليه",
  changePasswordp2 : "يجب أن تكون كلمة المرور الخاصة بك 6 أحرف على الأقل",
  currentPassword:"كلمة السر الحالية",
  newpassword:"كلمة المرور الجديدة",
  Retypenewpassword:"أعد كتابة كلمة السر الجديدة",
  account: "حساب (محفظة)",
  "Greek":"اليونانية",
  "el":"اليونانية",
  "registerBussinessUsername":"اسم المستخدم (5 أحرف على الأقل)",
  "registerBussinessPassword":"كلمة المرور (6 أحرف على الأقل)",
  "ReferenceID":"الرقم المرجعي",
  "EnterId":"توفير وثيقة الهوية",
  "Orderid": "رقم التعريف الخاص بالطلب",
  "EnterCode":"ادخل الرمز",
  "AustrianGerman":"النمساوية الألمانية",
  "deu":"النمساوية الألمانية",
  "individual": "فردي",
  "pop":"دليل على الالتقاط",
  "Options":"خيارات",
  "RequiredText":"مطلوب",
  "PleaseSelect":"الرجاء تحديد",
  "Optimizedropoffs":"تحسين المتسابقين",
  "Optimizedropoffs?":"تحسين المتسابقين؟",
  "OptimizedropoffsDesc":"سيتم تغيير تسلسل عمليات التسرب إلى تسلسل محسّن وقد يغير السعر",
  "Optimize":"تحسين"

,
  "Bulkimport":"الاستيراد بالجملة",
  "processing":"يعالج" ,
   "done":"انتهى" ,
   "failed":"فشل",
   "Draft":"مسودة" ,
   "Uploading":"التحميل" ,
   "Draganddropyourfile":"اسحب ملفك وإسقاطه",
   "Onlycsvformatissupported":"فقط تنسيق .CSV مدعوم" ,
   "ORyoucan":"أو يمكنك",
   "Openfile":"فتح الملف" ,
   "Noimportsyet":"لا واردات حتى الآن" ,
   "NoimportsyetDesc":"يمكنك رؤية قائمة الواردات الخاصة بك هنا والتحقق من حالتهم",
   "nosupportdialogeTitle":"تنسيق ملف غير معتمد",
   "nosupportdialogeText":"ملف xxx غير مدعوم.يمكنك فقط تحميل ملف .csv بما في ذلك معلومات الطلب" ,
   "Import":"يستورد",
   "previewTitle":"معاينة واختيار الخيارات",
   "previewDesc":"هذا هو العنصر الأول من ملفاتك للتحقق من معلوماتك في الهيكل الصحيح" ,
   "Upload":"رفع", 
   "Remove":"يزيل" ,
   "ServiceandOrdertype":"خدمة ونوع الطلب" ,
   "Fileisproccessing":"يتم معالجة الملف ...",
   "proccessingDesc":"يمكنك التحديث لمعرفة ما إذا كانت النتيجة جاهزة",
   "serverError":"خطأ في الخادم",
   "isRequired":"xxx مطلوب" ,
   "Result":"نتيجة",
   "ResultText":"يمكنك رؤية المشكلات هنا وتنزيل هذه الصفوف لإصلاحها بشكل منفصل",
   "ErrorDetails":"أوامر مع الخطأ",
   "ErrorDetailsText":"يتم سرد الطلبات ذات الأخطاء في الملف الذي يمكنك تنزيله أدناه.أنه يحتوي على تفاصيل خطأ لكل صف.",
   "DownloadErroredRows":"تنزيل الصفوف الخاطئة" ,
   "importedsuccessfully":"يتم استيراد أوامر xxx بنجاح" ,
   "ordershaserrors":"أوامر xxx لديها أخطاء",
   "refresh":"ينعش",
   "pleaseSellectParsel":"الرجاء تحديد الخدمة",
   "Ondemand":"على الطلب",
   "PickupandDelivery":"بيك آب والتسليم",
   "PickupDelivery":"بيك آب والتسليم",
   "Delivery":"توصيل",
   "Pickup":"يلتقط",
   "tab_Delivery":"توصيل",
   "tab_Ondemand":"على الطلب",
   "tab_Pickup":"يلتقط",
   "tab_PickupDelivery":"p&d",
   "Downloadsamplecsv":"تنزيلعينةCsv" ,
   "driverPhoto":"Driver Photo",
   "yourPhoto":"Your Photo",
   "UploadPhoto":"Upload Photo",
   "Photos":"Photos"  ,
   
   
   
   "TrackLink":"Track Link",
   "ShipmentLabel":"Shipment Label",
   "Draft":"Draft",
   "Receipt":"Receipt",
   "Confirm":"Confirm",
   "Cancel":"Cancel",
   "BulkEditStatusSummary":"Bulk Edit Status Summary",
   "Successful":"Successful",
   "Failed":"Failed",
   "OrderID":"Order ID",
   "Details":"Details",
   "Result":"Result",
   "New":"New",
   "Import":"Import",
   "Apply":"Apply",
   "Clear":"Clear",
   "Noresultfound":"No result found",
   "Typeyourkeyword":"Type your keyword",
   "Typeintheinputtostartsearch":"Type in the input to start search",
   "OnDemand":"On Demand",
   "P_Hub_D":"P_Hub_D",
   "AddressDetails":"Address Details",
   "Map":"Map",
   "Support":"Support",
   "BulkEditStatusxxx/yyy":"Bulk Edit Status xxx/yyy",
   "xxxSuccessfull,yyyFailed":"xxx Successfull, yyy Failed",
   "xxxfromyyy":"xxx from yyy",
   "xxxrows":"xxx rows",
   "xxxselected":"xxx selected",
   "xxxdropoffs":"+xxx dropoffs",
   "Status Updated at xxx":"Status Updated at xxx",
   'More':'More',
   'Summary':'Summary',
   'Prev':'Prev',
   'Next':'Next',
   'Done':'Done',
   "ClearAll":"Clear All",
   'Errorinloadingdata':'Error in loading data',
   'Therewasaproblemloadingdatapleasetryagainorcontactsupport':'There was a problem loading data. please try again or contact support',
   'Tryagain':'Try again',
   'ConfigTable' :'Config Table' ,
   'ActiveColumns':'Active Columns',
   'Createyourorder':'Create your order',
   'Yourorderswillbeshownhere':'Your orders will be shown here',
   'Pleasecheckyourfiltersorkeyword':'Please check your filters or keyword',
   'CustomerPhoto':'Customer Photo',
   "NoAttachments":"No Attachments",
   "AllAttachmentswillbeshownhere":"All Attachments will be shown here",
   "Scheduleoverlap":"The Schedule Pickup time must be before the Schedule Delivery time and have no overlap"
};
