export const RomanianLabel = {
  Cutomerpanel: "Panou pentru clienți",
  login: "Autentificare",
  Register: "Întregistrare",
  en: "Engleză",
  fa: "Persană",
  Person: "Persoană",
  Business: "Companie",
  Phonenumber: "Număr de telefon",
  Continue: "Continuă",
  phonenumberhasnotregisterd: "Numărul de telefon nu a înregistrat înregistrat",
  Username: "Nume de utilizator",
  title: "Titlu",
  Password: "Parola",
  ExOnro: "Ex. companie",
  Exstart: "Ex. ******",
  googleConnected: "Conectat prin Google",
  facebookConnected: "Conectat prin Facebook",
  appleConnected: "Conectat prin Apple ID",
  plsenterphonnumber: "Numărul tău de telefon",
  firstnnameloginplaceholder: "Ex Ion",
  lastnameloginplaceholder: "Ex Popescu",
  Firstname: "Prenume",
  Lastname: "Nume",
  email: "E-mail",
  emailPlaceholder: "(opțional) ex. example@example.com.",
  comingsoon: "In curand :",
  comingsoondesc: "Pentru a vă înregistra afacerea, vă rugăm să faceți clic aici",
  comingsoondesc2:
  "Pentru a crea un cont de companie, te rugăm să ne contactezi",
  contactUs: "contactaţi-ne",
  CustomerAlreadyRegistered:
    "Clientul a fost deja înregistrat, te rugăm sa te autentifici",
  registerCompleted: "Introdu codul de verificare",
  googletokenerror: "Problemă cu Google token",
  facebooktokenerror: "Problemă cu tokenul Facebook",
  appletokenerror: "Problemă cu tokenul Apple",
  CustomerNotRegistered: "Clientul nu este înregistrat",
  fr: "limba franceza",
  InvalidVerificationCode: "Cod de verificare invalid",
  a: "Informațiile tale nu au fost găsite",
  welcome: "Bine ai venit !",
  SmsLimitationError: "Încearcă din nou",
  resendCodeWithTime: "Retrimite codul (xxx)",
  resendCode: "retrimite codul",
  VerificationcodePlaceHolder: "Ex. 1234.",
  Verificationcode: "Cod de verificare",
  VerificationcodeText: "Introdul codull trimis la xxx",
  VerificationCodeExpired: "Codul de verificare a expirat",
  Pending: "în așteptare",
  Unassigned: "Neatribuită",
  Assigned: "Atribuită",
  PickedUp: "Preluată",
  Started: "Începută",
  statusInProgress: "În curs",
  Done: "Finalizată",
  CustomerCanceled: "Anulată de client",
  DriverCanceled: "Anulată de curier",
  SupportCanceled: "Anulată de asistență",
  Arrived: "Comandă livrată",
  new: "nou",
  Historyofyourorderswillbehere: "Istoria comenzilor tale va fi aici",
  otherdropoffs: "alte livrări",
  Orderhistory: "Istoric comenzi",
  Noorder: "Nu există comenzi",
  loadingList: "Ai rabdare, se încarcă informațiile",
  loadingError: "Problemă privind obținerea de informații",
  retry: "Încearcă din nou",
  Create_your_first_requst: "Creați o nouă comandă",
  List_of_you_active_orders_will_be_here: "Lista comenzilor active va fi aici",
  Aboutus: "Despre noi",
  Readour: "Citește",
  privaypolicy: "Politica de confidențialitate",
  Transactions: "Tranzacții",
  NoTransaction: "Nu ai tranzacții",
  Historyofyourransactionswillbehere: "Istoria tranzacțiilor va fi aici",
  Settings: "Setări",
  Language: "Limba",
  English: "Engleză",
  Persian: "Persană",
  French: "Franceză",
  Logout: "Deconectare",
  logotTitle: "Deconectare ?",
  logoutDescription: "O să fii deconectat de la cont",
  confirmLogout: "Da, deconecteaza-mă",
  skipLogout: "Anulează",
  savesettings: "Salvează setările",
  emailInvalid: "adresa de e-mail este invalida",
  Neworder: "Comandă nouă",
  Service: "Serviciu",
  poperLoading: "Te rugăm sa astepți",
  Pickup: "Pick up",
  SetPickup: "Punct de pick up",
  ChooseoOnMap: "Hartă",
  Fornow: "Deocamdata",
  Scheduled: "Pragramat",
  before: "Înainte:",
  after: "După:",
  confirm: "Confrim",
  Settime: "Selectează ora",
  fo: "Fo",
  Cancel: "Anulează",
  Shipmentlabel:"Eticheta de expediere",
  Pleasetryanotherkeyword: "Încearcă alt cuvânt",
  Noinformationfound: "Nu au fost găsite informații",
  address: "Adressa",
  block: "Bloc / clădire",
  Floor: "Etaj",
  Unit: "Apartament",
  senderfullname: "Numele complet al expeditorului",
  senderphonenumber: "Numărul de telefon al expeditorului",
  notesfordriver: "Notă pentru șofer",
  DropOff: "Setează pick up",
  SetDropOff: "Setează drop off",
  fetchingdata: "Primirea informațiilor ...",
  Recieverfullname: "Numele complet al destinatarului",
  Recieverphonenumber: "Numărul de telefon al destinatarului",
  undefined: "Tipul de serviciu",
  PromotionCode: "Cod discount",
  Delete: "Șterge",
  Save: "Salvează",
  PromoCodeValid: "Cod discount salvat",
  SenderWallet: "Balanța",
  SenderCash: "Numerar",
  ReceiverCash: "Numerar la livrare",
  lowBalance: "Balanța scăzuta",
  Balance: "Balanță",
  Methods: "Metode",
  balanceError: "Balanța trebuie să fie cel puțin cât valoarea comenzii",
  SendOrder: "Trimite comanda",
  feedback: "Opinie",
  Feedback: "Părere",
  Orderagain: "Comanda din nou",
  Createdsuccessfully: "Creat cu succes",
  chooseAmount: "Alege suma",
  orenterthepreferedamount: "Suma preferată:",
  enteramount: "prețul dorit",
  pay: "Plătește",
  selectPaymentGateway: "Selecteaza metoda de plată",
  Time_MachineText:
    "Doriți să vă recuperați informațiile de comandă anterior neînregistrate?",
  yes: "Da",
  ignore: "ignora",
  Order: "Comandă",
  statusPending: "în așteptare",
  statusUnassigned: "Neatribuită",
  statusAssigned: "Atribuită",
  statusStarted: "Începută",
  statusPickedUp: "Prealuată",
  statusDone: "Finalizată",
  statusCustomerCanceled: "Clientul a anulat comanda",
  statusDriverCanceled: "Curierul a anulat comanda",
  statusArrived: "Comandă livrată",
  statusSupportCanceled: "Comandă anulată de asistență",
  statusCanceled: "Anulată",
  Phone: "Telefon",
  additonalservice: "Serviciu adițional",
  CallDriver: "Sună curier",
  Message: "Mesaj",
  orderInfoError: "Eroare la primirea informațiilor",
  support: "Ajutor",
  map: "Hartă",
  receipt: "Proforma",
  photo: "Poză",
  ServicePhoto: "Poză serviciu",
  Addphoto: "Adauga poza",
  Estime: "Es.timp",
  Espath: "Es. cale",
  pod: "Dovadă de livrare",
  Notes: "Note",
  Photo: "Poza",
  Signature: "Semnătură",
  Nonotes: "Nu există note",
  Nosignature: "Nici o semnătură",
  Nophoto: "Nici o poză",
  Price: "Preț",
  Receiver: "Destinatar",
  Sender: "Expeditor",
  Cash: "Numerar",
  Canceltravel: "Anulează cererea",
  CancellText: "După anulare, comanda va fi anulată si la curier",
  Estimated: "Estimat",
  Wallet: "Balanță",
  Copylink: "Copiază adresa ",
  repeatOrder: "Repetă comanda",
  Edit: "Editează",
  confirmCancel: "Da",
  paymentmethod: "Modalitate de plată",
  SucceessFul: "Cu succes",
  errorinServer: "Problemă cu serverul",
  Comment: "Notă",
  Exfeedbackinput: "Ex a livrat foarte repede",
  Wallett: "Portofel",
  TypeHere: "Alimentat de Onro",
  Loadingmessages: "Încărcarea mesajelor",
  PoweredBy: "Powered Par",
  newincomemessage: "Ai primit un mesaj nou",
  newincomemessageanother: "Ai un mesaj nou într-o altă comandă",
  youhaveunreadmessage: "Mesaj necitit",
  Nointernetconnection: "Fără conexiune internet",
  close: "închide",
  seen: "Văzut",
  controlThatbeforeLoweEqualAfter:
    "Timpul înainte nu poate fi mai devreme decât după",
  controlThatbeforeAndAfterMustBiggerthanCurrentDate:
    "Înainte si după, timpul nu poate fi mai scurt decat timpul curent",
  errorInPanelSetting: "Eroare în plasarea comenzii, contacteaza asistența",
  Arabic: "Arabic",
  ar: "Arabic",
  veeDeliveryLoginText1: "Trimite ceva",
  veeDeliveryLoginText2: "Pentru oricine, acum!",
  Schedule: "Programează",
  Date: "Data",
  Time: "Timp",
  noDateToShow: "Nu există dată",
  noTimeToShow: "Nu există timp",
  Apply: "Aplica",
  Increaseyourbalance: "Adaugă credit",
  AddBalance: "Adaugă credit",
  Addressbook: "Listă adrese",
  AddressbookSearchbar: "Cauta dupa adresă sau nume",
  Removedefault: "Cauta dupa adresă sau nume",
  Setdefaultpickup: "Setează ca pick up uzual",
  Remove: "Șterge",
  newAddress: "Adresă nouă",
  NewAddressbookInfoTitle: "Informații",
  Addressdetails: "Detalii adresă",
  Title: "Titlu",
  Code: "Cod",
  editAddress: "Editează adresa",
  AddressbookDropOf: "Adrese drop off",
  AddressbookPickup: "Adrese pick up",
  hide: "Ascunde",
  Nosavedaddress: "Nu există adresă salvată",
  NosavedaddressDesc:
    "Puteți crea unul, făcând clic pe pictograma marcajului de lângă adresa sau accesați pagina de agendă",
  Romanian: "Română",
  ro: "Română",
  Vehicletype:"Tip vehicul" ,
  Pickup2:"Preluare" ,
  Dropoff2:"Livrare" ,
  senderfullname: "Nume expeditor",
  block: "Bloc",
  senderphonenumber: "Număr de telefon",
  Recieverfullname: "Nume destinatar",
  Recieverphonenumber: "Număr de telefon",

  
  Events: "Status",
  allEvents: "Toate statusurile ",
  star :"stea" ,
  Yourrate:"Calificativul tău" ,
  Comment:"Comentariu",
  CopyOrderlink:"Copiază link comandă",
  CopyPickuplink:"Copiază link preluare" ,
  CopyDeliverylink:"Copiază link livrare",
  paymentmethod: "Modalitate de plată",
  Name :"Nume",
  CashOnDelivery:"Plata la livrare",
  EnterAmount:"Introduceți suma",
  COD:"Plata la livrare",
  Surchargexxx:"Taxă suplimentară xxx.",
  Pricedetails: "Detalii despre preț",
  minxxx :"XXX Min.",
  toPickup:"a ridica" ,
  toDropOff: "pentru a renunța la",
  AddCreditCard:"Adăugați cardul de credit / debit" ,
  CustomerWallet:"Portofelul clientului" ,
  Add:"Adăuga",
  Addedsuccessfully: "Adăugat cu succes",
  paymentmethods: "Payment methods",
  NoCards:"No Cards",
  Listofyourcardswillbehere:"List of your cards will be here",
  CardInfo:"Card info",
  ExpireDate:"Expire date",
  CVC:"CVC",
  PostalCode:"Postal Code",
  RemoveCardq:"Remove Card?",
  RemoveCardA:"The card will be removed and can’t be used anymore",
  YesRemove:"Yes, Remove",
  Cardisinuse:"Cardul este în uz",
  ok:"Bine",
  Pleasetypeyourwordstosearch:"Vă rugăm să tastați cuvântul cheie pentru a căuta" ,
  spanish:"Spaniolă",
  es : "Spaniolă",
  Invoices:"Facturi",
  noInvoice:"Nr. XXX",
  germany: 'Germania',
  de: 'Germania',
  downloadClientApplication:"Descărcați aplicația xxx" ,


  AssignedAt: 'Assign till xxx',
  StartedAt: 'Începe până la xxx',
  ArrivedAt: 'Ajunge până la xxx',
  PickedUpAt: 'Pickup la xxx',
  DoneAt: 'Finalizați la xxx',
  Russian:"Rusă",
  Azerbaijani:"Azerbaidjani",
  rus:"Rusă",
  aze:"Azerbaidjani",
  Support:"A sustine",
  SendRequest:"Trimite cerere",
  CallTheSupport:"Apelați suportul",
  Choosethesubject :"Alegeți subiectul",
  Seefrequentquestions:"Vezi întrebări frecvente",
  Details:"Detalii",
  Send:"Trimite",
  MessageBox:"Cutie de mesaje",
  NoMessages:"Fără mesaje",
  Yourmessageswillbehere:"Mesajele dvs. vor fi aici",
  Portuguese:"Portugheză",
  pt:"Portugheză",
  Required:"Necesar",
  changePassword : "Schimbaţi parola",
  changePasswordp1 : "Veți fi deconectat din toate sesiunile, cu excepția acestuia, pentru a vă proteja contul, oricine încearcă să obțină acces",
  changePasswordp2 : "parolaDvsTrebuieSăFieDeCelPuțin6Caractere",
  currentPassword:"Parola actuală",
  newpassword:"Parolă Nouă",
  Retypenewpassword:"Re-tipați parola nouă",
  account: "Cont (portofel)",
  "Greek":"Grec",
  "el":"Grec",
  "registerBussinessUsername":"Numele de utilizator (cel puțin 5 caractere)",
  "registerBussinessPassword":"Parolă (cel puțin 6 caractere)",
  "ReferenceID":"ID de referinta",
  "EnterId":"Furnizați un document de identitate",
  "Orderid": "Comanda ID",
  "EnterCode":"Introdu codul",
  "AustrianGerman":"German austriac",
  "deu":"German austriac",
  "pop":"Dovada preluării",
  "Options":"Opțiuni",
  "RequiredText":"Necesar",
  "PleaseSelect":"Te rog selecteaza",
  "Optimizedropoffs":"Optimizați abandonul",
  "Optimizedropoffs?":"Optimizați abandonul?",
  "OptimizedropoffsDesc":"Secvența abandonului va fi modificată în secvența optimizată și poate modifica prețul",
  "Optimize":"Optimizează"

  ,
  "Bulkimport":"Import în vrac",
  "processing":"Prelucrare" ,
   "done":"Terminat" ,
   "failed":"A eșuat",
   "Draft":"Proiect" ,
   "Uploading":"Încărcare" ,
   "Draganddropyourfile":"Trageți și aruncați fișierul",
   "Onlycsvformatissupported":"Numai formatul .CSV este acceptat" ,
   "ORyoucan":"Sau puteți",
   "Openfile":"Deschide fișierul" ,
   "Noimportsyet":"Nu există încă importuri" ,
   "NoimportsyetDesc":"Puteți vedea lista importurilor dvs. aici și puteți verifica starea acestora",
   "nosupportdialogeTitle":"Format de fișier nu este acceptat",
   "nosupportdialogeText":"Fișierul XXX nu este acceptat.Puteți încărca doar fișierul .csv, inclusiv informațiile despre comandă" ,
   "Import":"import",
   "previewTitle":"Previzualizați și alegeți opțiuni",
   "previewDesc":"Acesta este primul element al fișierelor dvs. care vă verifică informațiile sunt în structura potrivită" ,
   "Upload":"Încărcați", 
   "Remove":"Elimina" ,
   "ServiceandOrdertype":"Tip de serviciu și comandă" ,
   "Fileisproccessing":"Fișierul procesează ...",
   "proccessingDesc":"Puteți reîmprospăta pentru a vedea dacă rezultatul este gata",
   "serverError":"Eroare de server",
   "isRequired":"XXX este necesar" ,
   "Result":"Rezultat",
   "ResultText":"Puteți vedea problemele aici și descărcați aceste rânduri pentru a remedia separat",
   "ErrorDetails":"Comenzi cu eroare",
   "ErrorDetailsText":"Comenzile cu erori sunt listate în fișierul pe care îl puteți descărca mai jos.Conține detalii de eroare pentru fiecare rând.",
   "DownloadErroredRows":"Descărcați rânduri eronate" ,
   "importedsuccessfully":"Comenzile XXX sunt importate cu succes" ,
   "ordershaserrors":"comenzile XXX au erori",
   "refresh":"Reîmprospăta",
   "pleaseSellectParsel":"Vă rugăm să selectați Serviciul",
   "Ondemand":"La cerere",
   "PickupandDelivery":"Ridicare și livrare",
   "PickupDelivery":"Ridicare și livrare",
   "Delivery":"Livrare",
   "Pickup":"Ridica",
   "tab_Delivery":"Livrare",
   "tab_Ondemand":"La cerere",
   "tab_Pickup":"Ridica",
   "tab_PickupDelivery":"P&D",
   "Downloadsamplecsv":"Descărcați eșantionul CSV" ,
   "driverPhoto":"Driver Photo",
   "yourPhoto":"Your Photo",
   "UploadPhoto":"Upload Photo",
   "Photos":"Photos",
   
   
   
   "TrackLink":"Track Link",
   "ShipmentLabel":"Shipment Label",
   "Draft":"Draft",
   "Receipt":"Receipt",
   "Confirm":"Confirm",
   "Cancel":"Cancel",
   "BulkEditStatusSummary":"Bulk Edit Status Summary",
   "Successful":"Successful",
   "Failed":"Failed",
   "OrderID":"Order ID",
   "Details":"Details",
   "Result":"Result",
   "New":"New",
   "Import":"Import",
   "Apply":"Apply",
   "Clear":"Clear",
   "Noresultfound":"No result found",
   "Typeyourkeyword":"Type your keyword",
   "Typeintheinputtostartsearch":"Type in the input to start search",
   "OnDemand":"On Demand",
   "P_Hub_D":"P_Hub_D",
   "AddressDetails":"Address Details",
   "Map":"Map",
   "Support":"Support",
   "BulkEditStatusxxx/yyy":"Bulk Edit Status xxx/yyy",
   "xxxSuccessfull,yyyFailed":"xxx Successfull, yyy Failed",
   "xxxfromyyy":"xxx from yyy",
   "xxxrows":"xxx rows",
   "xxxselected":"xxx selected",
   "xxxdropoffs":"+xxx dropoffs",
   "Status Updated at xxx":"Status Updated at xxx",
   'More':'More',
   'Summary':'Summary',
   'Prev':'Prev',
   'Next':'Next',
   'Done':'Done',
   "ClearAll":"Clear All",
   'Errorinloadingdata':'Error in loading data',
   'Therewasaproblemloadingdatapleasetryagainorcontactsupport':'There was a problem loading data. please try again or contact support',
   'Tryagain':'Try again',
   'ConfigTable' :'Config Table' ,
   'ActiveColumns':'Active Columns',
   'Createyourorder':'Create your order',
   'Yourorderswillbeshownhere':'Your orders will be shown here',
   'Pleasecheckyourfiltersorkeyword':'Please check your filters or keyword',
   "Scheduleoverlap":"The Schedule Pickup time must be before the Schedule Delivery time and have no overlap"
};
