export const EspanishLabel = {
  "Cutomerpanel": "Spanish",
  "login": "Acesso",
  "Register": "Registrarse",
  "en": "Ingles",
  "fa": "Persa",
  "individual": "Persona",
  "Business": "Negocio",
  "Phonenumber": "Numero de telefono",
  "Continue": "continuar",
  "phonenumberhasnotregisterd": "Numero no registrado",
  "Username": "Nombre de usuario",
  "title": "Titulo",
  "Password": "Contraseña",
  "ExOnro": "Empresa de ejemplo",
  "Exstart": "Ex.******",
  "googleConnected": "Concetado a google",
  "facebookConnected": "Conectado a facebook",
  "appleConnected": "Apple Id conectado",
  "plsenterphonnumber": "Favor introduzca su numero de telefono",
  "firstnnameloginplaceholder": "Ejemplo Jhon",
  "lastnameloginplaceholder": "Ejemplo Doe",
  "Firstname": "Primer nombre",
  "Lastname": "Apellido",
  "email": "Correo",
  "emailPlaceholder": "Example@example.com ejemplo (Opcional)",
  "comingsoon": "Proximamente",
  comingsoondesc: "Para registrar su negocio, haga clic aquí",
  contactUs: "Contáctenos",
  "comingsoondesc2": "Por favor contactanos para crear una cuenta de negocio",

  "CustomerAlreadyRegistered": "Cliente registrado por favor inicie sesion ",
  "registerCompleted": "Entrar un codigo de verificacion",
  "googletokenerror": "Problema con el token de google",
  "facebooktokenerror": "Problema con el token de facebook",
  "appletokenerror": "Problema con el token de apple",
  "CustomerNotRegistered": "Cliente no registrado",
  "fr": "Frances",
  "InvalidVerificationCode": "Codigo de verificacion invalido ",
  "a": "Su informacion no pudo ser encontrada",
  "welcome": "Bienvenido ",
  "SmsLimitationError": "Vuelva aintentarlo en unos minutos",
  "resendCodeWithTime": "Reenviar codigo (xxx)",
  "resendCode": "Reenviar codigo",
  "VerificationcodePlaceHolder": "Ejemplo 1234",
  "Verificationcode": "Codigo de verificacion",
  "VerificationcodeText": "Introduzca el codigo enviado a xxx",
  "VerificationCodeExpired": "Codigo de verificacion expirado",
  "Pending": "Pendiente",
  "Unassigned": "Sin asignar",
  "Assigned": "En progreso",
  "PickedUp": "En progreso",
  "Started": "En progreso",
  "statusInProgress": "En progreso",
  "Done": "Iniciada",
  "CustomerCanceled": "Cancelada",
  "DriverCanceled": "Cancelada",
  "SupportCanceled": "Cancelada",
  "Arrived": "En progreso",
  "new": "Nuevo",
  "Historyofyourorderswillbehere": "El historial de sus pedidos estara aqui",
  "otherdropoffs": "Otras entregas",
  "Orderhistory": "Historial de pedidos",
  "Noorder": "Sin pedidos",
  "loadingList": "Sea paciente mientras recibe informacion ",
  "loadingError": "Problema al obtener informacion ",
  "retry": "Intente de nuevo",
  "Create_your_first_requst": "Crea un nuevo pedido",
  "List_of_you_active_orders_will_be_here": "La lista de sus pedidos activos estara aqui ",
  "Aboutus": "Sobre nosotros",
  "Readour": "Lee nuestro",
  "privaypolicy": "Politica de privacidad",
  "Transactions": "Transaciones ",
  "NoTransaction": "Usted no tiene transacciones ",
  "Historyofyourransactionswillbehere": "El historial de sus transacciones estara aqui ",
  "Settings": "Ajustes",
  "Language": "Idioma",
  "English": "Ingles",
  "Persian": "Perso",
  "French": "Frances ",
  "Logout": "Cerrar sesion ",
  "logotTitle": "Cerrar sesion de la cuenta",
  "logoutDescription": "Se cerrara la sesion de la cuenta , debe inciar sesion mas tarde",
  "confirmLogout": "Cerrar sesion ",
  "skipLogout": "Cancelar",
  "savesettings": "Guardar ajustes",
  "emailInvalid": "Correo invalido",
  "Neworder": "Nuevo pedido",
  "Service": "Servicio",
  "poperLoading": "Por favor espere",
  "Pickup": "Recoger",
  "SetPickup": "Establecer recogida",
  "ChooseoOnMap": "Seleccione en el mapa ",
  "Fornow": "Por ahora",
  "Scheduled": "Horario",
  "before": "Antes:",
  "after": "Despues:",
  "confirm": "Confirmar",
  "Settime": "Establecer la hora",
  "fo": "Para",
  "Cancel": "Cancelar",
  "Shipmentlabel": "Etiqueta de Entrega",
  "Pleasetryanotherkeyword": "Intente otra palabra clave ",
  "Noinformationfound": "Informacion no encontrada",
  "address": "Direccion",
  "block": "Bloquear",
  "Floor": "Piso",
  "Unit": "Unidad",
  "senderfullname": "Nombre de remitente",
  "senderphonenumber": "Numero de remitente",
  "notesfordriver": "Notas para el conductor",
  "DropOff": "Dejar",
  "SetDropOff": "Establecer dejar",
  "fetchingdata": "Recibiendo información...",
  "Recieverfullname": "Nombre de receptor",
  "Recieverphonenumber": "Numero de receptor",
  "undefined": "Tipo de servicio",
  "PromotionCode": "Codigo promocional",
  "Delete": "Eliminar",
  "Save": "Guardar",
  "PromoCodeValid": "Codigo promocional guardado",
  "SenderWallet": "Cartera",
  "SenderCash": "Efectivo",
  "ReceiverCash": "pago por receptor",
  "lowBalance": "Saldo bajo",
  "Balance": "Billetera",
  "Methods": "Metodos",
  "balanceError": "Su saldo debe ser al menos igual al de su pedido",
  "SendOrder": "Enviar orden",
  "feedback": "Retroalimentación",
  "Feedback": "Retroalimentación",
  "Orderagain": "Ordenar de nuevo",
  "Createdsuccessfully": "Creado satisfactoriamente",
  "chooseAmount": "Seleccione monto",
  "orenterthepreferedamount": "Monto preferido:",
  "enteramount": "Precio deseado",
  "pay": "Pagar",
  "selectPaymentGateway": "Seleccione pasarela de pago",
  "Time_MachineText": "¿Desea recuperar la información de su pedido no registrado anteriormente?",
  "yes": "Sí",
  "ignore": "Ignorar",
  "Order": "Orden",
  "statusPending": "Pendiente",
  "statusUnassigned": "Sin asignar",
  "statusAssigned": "Asignada",
  "statusStarted": "Iniciada",
  "statusPickedUp": "Recogida",
  "statusDone": "Realizada",
  "statusCustomerCanceled": "El cliente ha cancelado",
  "statusDriverCanceled": "El repartidor ha cancelado",
  "statusArrived": "Ha llegado",
  "statusSupportCanceled": "Cancelada por soporte",
  "statusCanceled": "Cancelada",
  "Phone": "Teléfono",
  "additonalservice": "Servicios adicionales",
  "CallDriver": "Llamar al repartidor",
  "Message": "Mensaje",
  "orderInfoError": "Error recibiendo información",
  "support": "Soporte",
  "map": "Mapa",
  "receipt": "Recibo",
  "photo": "Foto",
  "ServicePhoto": "Foto de servicio",
  "Addphoto": "Añadir foto",
  "Estime": "Tiempo estimado",
  "Espath": "Ruta estimada",
  "pod": "Muestra de delivery",
  "Notes": "Notas",
  "Photo": "Foto",
  "Signature": "Firma",
  "Nonotes": "Sin notas",
  "Nosignature": "Sin firma",
  "Nophoto": "Sin foto",
  "Price": "Precio",
  "Receiver": "Por receptor",
  "Sender": "Por remitente",
  "Cash": "Efectivo",
  "Canceltravel": "Petición cancelada",
  "CancellText": "Luego de la cancelación, la orden será cancelada para el repartidor",
  "Estimated": "Estimado",
  "Wallet": "Billetera",
  "Copylink": "Copiar enlace",
  "repeatOrder": "Repetir orden",
  "Edit": "Editar",
  "confirmCancel": "Si",
  "paymentmethod": "Método de Pago",
  "SucceessFul": "Completado correctamente",
  "errorinServer": "Problema en servidor",
  "Comment": "Comentario",
  "Exfeedbackinput": "Ejemplo: entregó muy rápido",
  "Wallett": "Billetera",
  "TypeHere": "Escriba aquí",
  "Loadingmessages": "Cargando mensajes",
  "PoweredBy": "Desarrollado por ",
  "newincomemessage": "Has recibido un nuevo mensaje",
  "newincomemessageanother": "Tienes nuevos mensajes en otra orden",
  "youhaveunreadmessage": "Tienes mensajes sin leer",
  "Nointernetconnection": "Sin conexión a Internet",
  "close": "Cerrado",
  "seen": "Visto",
  "controlThatbeforeLoweEqualAfter": "Tiempo \"Despues:\" no puede ser menor que \"Antes:\"",
  "controlThatbeforeAndAfterMustBiggerthanCurrentDate": "\"Antes:\" y \"Despues: \" no pueden ser menores al tiempo actual",
  "errorInPanelSetting": "Error en ajustes de reservació, por favor contactar con soporte",
  "Arabic": "Arabic",
  "ar": "Arabic",
  "veeDeliveryLoginText1": "Enviando algo",
  "veeDeliveryLoginText2": "Para todos, AHORA!",
  "Schedule": "Programar",
  "Date": "Fecha",
  "Time": "Tiempo",
  "noDateToShow": "No hay tiempo para mostrar",
  "noTimeToShow": "No hay tiempo para mostrar",
  "Apply": "Aplicar",
  "Increaseyourbalance": "Aumentar Balance",
  "AddBalance": "Añadir balance",
  "Addressbook": "Libro de direcciones",
  "AddressbookSearchbar": "Buscar por dirección, título y código",
  "Removedefault": "Buscar por dirección, título y código",
  "Setdefaultpickup": "Establecer recogida predeterminada",
  "Remove": "Remover",
  "newAddress": "Nueva dirección",
  "NewAddressbookInfoTitle": "Info",
  "Addressdetails": "Detalles de dirección",
  "Title": "Título",
  "Code": "Código",
  "editAddress": "Editar dirección",
  "AddressbookDropOf": "Libreta de direcciones (entrega)",
  "AddressbookPickup": "Libreta de direcciones (recogida)",
  "hide": "Ocultar",
  "Nosavedaddress": "Sin direcciones guardadas",
  "NosavedaddressDesc": "Puede crear uno, haciendo clic en el icono de marcador junto a la dirección o vaya a la página de la libreta de direcciones",
  "Romanian": "Rumano",
  "ro": "Rumano",
  "Vehicletype": "Tipo de Vehiculo",
  "Pickup2": "Recogida",
  "Dropoff2": "Entrega",
  "Events": "Eventos",
  "allEvents": "Todos los eventos",
  "star": "Estrella",
  "Yourrate": "Su tarifa",
  "CopyOrderlink": "Copiar enlace de orden",
  "CopyPickuplink": "Copiar enlace de recogida",
  "CopyDeliverylink": "Copiar enlace de entrega",
  "Dutch": "Holandes",
  "Name": "Nombre",
  "CashOnDelivery": "Efectivo en la entrega",
  "EnterAmount": "Precio deseado",
  "COD": "Efectivo en la entrega",
  "Surchargexxx": "Recargo xxx",
  "Pricedetails": "Detalles del precio",
  "minxxx": "xxx min",
  "toPickup": "recoger",
  "toDropOff": "para entregar",
  "AddCreditCard": "Agregar tarjeta de crédito/débito",
  "CustomerWallet": "Billetera del cliente",
  "Add": "Agregar",
  "Addedsuccessfully": "Agregado exitosamente",
  "paymentmethods": "Métodos de pago",
  "NoCards": "Sin cartas",
  "Listofyourcardswillbehere": "La lista de sus tarjetas estará aquí",
  "CardInfo": "Información de la tarjeta",
  "ExpireDate": "Fecha de caducidad",
  "CVC": "CVC",
  "PostalCode": "Código postal",
  "RemoveCardq": "¿Retirar la tarjeta?",
  "RemoveCardA": "La tarjeta se eliminará y no se puede usar más",
  "YesRemove": "Sí, eliminar",
  "Cardisinuse": "La tarjeta está en uso",
  "ok": "ok",
  "Pleasetypeyourwordstosearch": "Escriba su palabra clave para buscar",
  "spanish": "español",
  "es": "español",
  "Invoices": "facturas",
  "noInvoice": "no xxx",
  germany: 'Alemania',
  de: 'Alemania',
  downloadClientApplication:"Descargar aplicación xxx" ,

  AssignedAt: 'Asignar hasta xxx',
  StartedAt: 'Empiece hasta xxx',
  ArrivedAt: 'Llegue hasta xxx',
  PickedUpAt: 'Recogida en xxx',
  DoneAt: 'Terminar en xxx',
  Russian:"Rusa",
  Azerbaijani:"Azerbaiyán",
  rus:"Rusa",
  aze:"Azerbaiyán",
  Support:"Apoyo",
  SendRequest:"Enviar petición",
  CallTheSupport:"Call the support",
  Choosethesubject :"Elija el tema",
  Seefrequentquestions:"Ver preguntas frecuentes",
  Details:"Detalles",
  Send:"Enviar",
  MessageBox:"Buzon de mensaje",
  NoMessages:"Ningún mensaje",
  Yourmessageswillbehere:"Tus mensajes estarán aquí",
  Portuguese:"Portuguesa",
  pt:"Portuguesa",
  Required:"Requerida",
  changePassword : "Cambiar la contraseña",
  changePasswordp1 : "Se registrará fuera de todas las sesiones, excepto esta para proteger su cuenta, cualquiera está tratando de obtener acceso",
  changePasswordp2 : "Tu contraseña debe tener al menos 6 caracteres",
    currentPassword:"Contraseña actual",
    newpassword:"Nueva contraseña",
    Retypenewpassword:"Reescriba nueva contraseña",
    account: "Cuenta (billetera)",
    "Greek":"Griega",
    "el":"Griega",
    "registerBussinessUsername":"Nombre de usuario (al menos 5 caracteres)",
    "registerBussinessPassword":"Contraseña (al menos 6 caracteres)",
    "ReferenceID":"Identificación de referencia",
    "EnterId":"Proporcionar un documento de identidad",
    "Orderid": "Solicitar ID",
    "EnterCode":"Introduzca el código",
    "AustrianGerman":"Alemán austriaco",
    "deu":"Alemán austriaco",
    "pop":"Prueba de recogida",
    "Options":"Opción",
    "RequiredText":"Requerida",
    "PleaseSelect":"Seleccione",
    "Optimizedropoffs":"Optimizar las caídas",
    "Optimizedropoffs?":"Optimizar las caídas?",
    "OptimizedropoffsDesc":"La secuencia de caídas se cambiará a una secuencia optimizada y puede cambiar el precio",
    "Optimize":"Optimizar",
    "Bulkimport":"Importación a granel",
    "processing":"Procesando" ,
     "done":"Finalizada" ,
     "failed":"Fallida",
     "Draft":"Borrador" ,
     "Uploading":"Carga" ,
     "Draganddropyourfile":"Arrastre y suelte su archivo",
     "Onlycsvformatissupported":"Solo se admite el formato .CSV" ,
     "ORyoucan":"O tu puedes",
     "Openfile":"Abrir documento" ,
     "Noimportsyet":"Todavía no hay importaciones" ,
     "NoimportsyetDesc":"Puede ver la lista de sus importaciones aquí y verificar su estado",
     "nosupportdialogeTitle":"Formato de archivo no soportado",
     "nosupportdialogeText":"El archivo XXX no es compatible.Solo puede cargar el archivo .csv, incluida la información del pedido" ,
     "Import":"Importar",
     "previewTitle":"Vista previa y elija opciones",
     "previewDesc":"Este es el primer elemento de sus archivos para verificar su información está en la estructura correcta." ,
     "Upload":"Subir", 
     "Remove":"Eliminar" ,
     "ServiceandOrdertype":"Tipo de servicio y pedido" ,
     "Fileisproccessing":"El archivo está procesando ...",
     "proccessingDesc":"Puede actualizar para ver si el resultado está listo",
     "serverError":"Error del Servidor",
     "isRequired":"Se requiere xxx" ,
     "Result":"Resultado",
     "ResultText":"Puede ver los problemas aquí y descargar estas filas para arreglar por separado",
     "ErrorDetails":"Pedidos con error",
     "ErrorDetailsText":"Los pedidos con errores se enumeran en el archivo que puede descargar a continuación.Contiene detalles de error para cada fila.",
     "DownloadErroredRows":"Descargar filas erróneas" ,
     "importedsuccessfully":"Los pedidos xxx se importan con éxito" ,
     "ordershaserrors":"Los pedidos xxx tienen errores",
     "refresh":"Actualizar",
     "pleaseSellectParsel":"Seleccione Servicio",
     "Ondemand":"Bajo demanda",
     "PickupandDelivery":"Recogida y entrega",
     "PickupDelivery":"Recogida y entrega",
     "Delivery":"Entrega",
     "Pickup":"Levantar",
     "tab_Delivery":"Entrega",
     "tab_Ondemand":"Bajo demanda",
     "tab_Pickup":"Levantar",
     "tab_PickupDelivery":"P&D",
     "Downloadsamplecsv":"Descargar CSV de muestra" ,
     "driverPhoto":"Driver Photo",
     "yourPhoto":"Your Photo",
     "UploadPhoto":"Upload Photo",
     "Photos":"Photos",
   
   
   
     "TrackLink":"Track Link",
     "ShipmentLabel":"Shipment Label",
     "Draft":"Draft",
     "Receipt":"Receipt",
     "Confirm":"Confirm",
     "Cancel":"Cancel",
     "BulkEditStatusSummary":"Bulk Edit Status Summary",
     "Successful":"Successful",
     "Failed":"Failed",
     "OrderID":"Order ID",
     "Details":"Details",
     "Result":"Result",
     "New":"New",
     "Import":"Import",
     "Apply":"Apply",
     "Clear":"Clear",
     "Noresultfound":"No result found",
     "Typeyourkeyword":"Type your keyword",
     "Typeintheinputtostartsearch":"Type in the input to start search",
     "OnDemand":"On Demand",
     "P_Hub_D":"P_Hub_D",
     "AddressDetails":"Address Details",
     "Map":"Map",
     "Support":"Support",
     "BulkEditStatusxxx/yyy":"Bulk Edit Status xxx/yyy",
     "xxxSuccessfull,yyyFailed":"xxx Successfull, yyy Failed",
     "xxxfromyyy":"xxx from yyy",
     "xxxrows":"xxx rows",
     "xxxselected":"xxx selected",
     "xxxdropoffs":"+xxx dropoffs",
     "Status Updated at xxx":"Status Updated at xxx",
     'More':'More',
     'Summary':'Summary',
     'Prev':'Prev',
     'Next':'Next',
     'Done':'Done',
     "ClearAll":"Clear All",
     'Errorinloadingdata':'Error in loading data',
     'Therewasaproblemloadingdatapleasetryagainorcontactsupport':'There was a problem loading data. please try again or contact support',
     'Tryagain':'Try again',
     'ConfigTable' :'Config Table' ,
     'ActiveColumns':'Active Columns',
     'Createyourorder':'Create your order',
     'Yourorderswillbeshownhere':'Your orders will be shown here',
     'Pleasecheckyourfiltersorkeyword':'Please check your filters or keyword',
   "Scheduleoverlap":"The Schedule Pickup time must be before the Schedule Delivery time and have no overlap"
}